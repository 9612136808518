.box-white {
  background: #ffffff;
  box-sizing: border-box;
  display: block;
  border-radius: 8px;
}

.box-white.ws {
  box-shadow: 0px 12px 26px rgba(54, 95, 92, 0.06);
}

.box-white.wb {
  border: 1px solid #e8e8e8;
}

.overflow-hidden {
  overflow: hidden !important;
}

.table-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 24px 20px;
  flex-wrap: wrap;
}

.for-paging {
  width: 100%;
  margin-top: 15px;
}
